<script setup lang="ts">
import { __, n__ } from '@@/bits/intl'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzBadge, { OzBadgeColorScheme, OzBadgeSizePreset } from '@@/library/v4/components/OzBadge.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzListRow, { OzListRowSeparator } from '@@/library/v4/components/OzListRow.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import OzRelativeTime from '@@/library/v4/components/OzRelativeTime.vue'
import type { WallWithCurrentUserInfo } from '@@/pinia/padlet_picker'
import type { Library, WallCamelCase as Wall, Wall as WallSnakeCase } from '@@/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    wall: Wall | WallSnakeCase | WallWithCurrentUserInfo
    darkMode?: boolean | 'auto'
    invertColors?: boolean
    dateAttribute?: string
    /**
     * Set this to false to use normal cursor, tabindex -1 etc
     */
    clickable?: boolean
    navigateOnClick?: boolean
    xMore?: boolean
    /**
     * Whether there is a library associated with the wall
     */
    library?: Library | null
    separator?: OzListRowSeparator
    disabled?: boolean
  }>(),
  {
    darkMode: 'auto',
    invertColors: false,
    /**
     * The timestamp type to show.
     */
    dateAttribute: 'updatedAt',
    clickable: true,
    navigateOnClick: true,
    xMore: false,
    library: undefined,
    separator: OzListRowSeparator.Short,
    disabled: false,
  },
)

const emit = defineEmits<{
  (name: 'select', wall: Wall | WallSnakeCase): void
  (name: 'more', wall: Wall | WallSnakeCase): void
  (name: 'select-disabled-item'): void
}>()

const title = computed((): string => {
  return props.wall.title || __('Untitled padlet')
})

const wallLabel = computed((): string => {
  return props.clickable && props.navigateOnClick
    ? __('Go to %{wallTitle} padlet', { wallTitle: title.value })
    : __('Select %{wallTitle} padlet', { wallTitle: title.value })
})

const dominantColorAsRgb = computed((): string => {
  return (
    (props.wall as Wall).thumbnail.dominantColorAsRgb || (props.wall as WallSnakeCase).thumbnail.dominant_color_as_rgb
  )
})

const date = computed((): string => {
  if (props.dateAttribute === 'updatedAt') {
    return (props.wall as Wall).updatedAt || (props.wall as WallSnakeCase).updated_at
  } else {
    return props.wall[props.dateAttribute]
  }
})

const getRowAuthorDisplayName = (wall: Wall | WallSnakeCase | WallWithCurrentUserInfo): string | undefined => {
  const wallWithUserInfo = wall as WallWithCurrentUserInfo
  if (wallWithUserInfo.currentUserCanWrite == null || wallWithUserInfo.currentUserIsBuilder == null) {
    return undefined
  }

  if (!wallWithUserInfo.currentUserCanWrite) {
    return __('No writer permissions')
  }

  return undefined
}

const accountDisplayName = computed((): string => {
  const authorDisplayText = getRowAuthorDisplayName(props.wall)
  const displayName =
    authorDisplayText ??
    ('displayName' in props.wall.builder ? props.wall.builder.displayName : props.wall.builder.display_name)
  return props.library ? props.library.name : displayName
})

const daysTillWallIsDeleted = computed((): number => {
  const daysLeft =
    'daysTillDeletion' in props.wall ? props.wall.daysTillDeletion : (props.wall as WallSnakeCase).days_till_deletion
  return daysLeft ?? 0
})

const daysTillDeletedDisplay = computed((): string => {
  return n__('%{num} day left', '%{num} days left', daysTillWallIsDeleted.value, { num: daysTillWallIsDeleted.value })
})

const wallDeletingSoon = computed((): boolean => {
  return daysTillWallIsDeleted.value <= 3
})

const xRelativeTimestamp = computed((): boolean => {
  return props.dateAttribute !== 'trashedAt'
})

const xTemplate = computed((): boolean => {
  if ('isTemplate' in props.wall) {
    return props.wall.isTemplate === true
  }
  return false
})

const isFaded = computed(() => {
  const wallWithUserInfo = props.wall as WallWithCurrentUserInfo

  if (wallWithUserInfo.currentUserCanWrite == null || wallWithUserInfo.currentUserIsBuilder == null) {
    return false
  }
  if (wallWithUserInfo.currentUserCanWrite) {
    return false
  }
  return true
})

const select = (): void => {
  if (props.disabled) {
    emit('select-disabled-item')
    return
  }
  emit('select', props.wall)
}

const more = (): void => {
  emit('more', props.wall)
}
</script>

<script lang="ts">
export { OzListRowSeparator } from '@@/library/v4/components/OzListRow.vue'
export default {}
</script>

<template>
  <OzListRow
    :dark-mode="darkMode"
    :separator="separator"
    :clickable="clickable"
    :disabled="disabled"
    :aria-label="wallLabel"
    @click="select"
  >
    <template #left>
      <ImageThumbnail
        :class="[
          'rounded-lg rounded-br-2xl rtl:rounded-br-lg rtl:rounded-bl-2xl',
          'mr-4 rtl:mr-0 rtl:ml-4',
          'shrink-0',
          isFaded && 'opacity-30',
        ]"
        :src="wall.thumbnail.url"
        :placeholder-color-value="dominantColorAsRgb"
        :placeholder-color="'random'"
        :fallback-to-placeholder="true"
        :original-image-width="wall.thumbnail.width || 36"
        :original-image-height="wall.thumbnail.height || 36"
        :width="36"
        :process-image="true"
        :aspect-ratio="1"
      />
    </template>

    <div class="my-2 relative overflow-hidden">
      <div
        :class="[
          'text-17-21',
          'font-semibold',
          invertColors
            ? {
                'text-dark-text-100': darkMode === true && !isFaded,
                'text-dark-text-300': darkMode === true && isFaded,
                'text-light-text-100': darkMode === false && !isFaded,
                'text-light-text-300': darkMode === false && isFaded,
                'dark:text-dark-text-100 text-light-text-100': darkMode === 'auto' && !isFaded,
                'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto' && isFaded,
              }
            : {
                'text-light-text-100': darkMode === true && !isFaded,
                'text-light-text-300': darkMode === true && isFaded,
                'text-dark-text-100': darkMode === false && !isFaded,
                'text-dark-text-300': darkMode === false && isFaded,
                'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto' && !isFaded,
                'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto' && isFaded,
              },
          'truncate',
        ]"
      >
        {{ title }}
      </div>
      <div
        :class="[
          'text-body-extra-small',
          'flex',
          'leading-4',
          invertColors
            ? {
                'text-dark-text-100': darkMode === true,
                'text-light-text-100': darkMode === false,
                'dark:text-dark-text-100 text-light-text-100': darkMode === 'auto',
              }
            : {
                'text-light-text-200': darkMode === true,
                'text-dark-text-200': darkMode === false,
                'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
              },
        ]"
      >
        <span class="truncate">{{ accountDisplayName }}</span>
        <span
          :class="[
            invertColors
              ? {
                  'text-dark-text-300': darkMode === true,
                  'text-light-text-300': darkMode === false,
                  'text-light-text-300 dark:text-dark-text-300': darkMode === 'auto',
                }
              : {
                  'text-light-text-300': darkMode === true,
                  'text-dark-text-300': darkMode === false,
                  'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
                },
          ]"
          >&nbsp;•&nbsp;</span
        >
        <OzRelativeTime v-if="xRelativeTimestamp" :datetime="date" dateformat="short" />
        <span v-else :class="[wallDeletingSoon && 'text-danger-100']">{{ daysTillDeletedDisplay }}</span>
        <OzBadge
          v-if="xTemplate"
          class="h-4 ms-3 bg-teal-600 text-light-text-100"
          :text="__('Template')"
          :size-preset="OzBadgeSizePreset.Tiny"
          :color-scheme="OzBadgeColorScheme.Bare"
        />
      </div>
    </div>

    <template v-if="xMore" #right>
      <OzPlainButton
        :class="['ms-1 -me-1']"
        :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
        :size-preset="OzPlainButtonSizePreset.H28px"
        :data-testid="title"
        :aria-label="__('More')"
        @click.stop="more"
      >
        <OzIcon :size="20" name="more_vertical" />
      </OzPlainButton>
    </template>
  </OzListRow>
</template>
